.side-content  .ant-checkbox-group-item {
  width: 100%;
}

.side-content {
  margin-left: 10px;
  margin-top: 10px;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 18px;
  margin-top: 10px;
}

html, body {
  height: 100%;
  overflow: hidden;
}

.credit {
  margin-top: 40px;
  font-size: 12px;
}
.header {
    height: 64px; 
    line-height: 32px;
    padding: 10px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.total-downloads {
line-height: 40px;
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, sans-serif;
  font-style: normal;
  color: #555555;
}